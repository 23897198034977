import { useQrCodes } from "~/api/qrcode/queries";
import { ILimit, Limits } from "~/types/billing";
import { useAccount } from "./useAccount";

export const usePlanFeature = () => {
  const { data: qrCodes } = useQrCodes();
  const { account } = useAccount();

  const isFeatureEnabled = (limitType: Limits) => {
    const limits: ILimit | Record<any, any> =
      account?.active_subscription?.plan?.limits || {};

    if (account?.active_subscription?.paused_at) return false;

    if (limitType === Limits.QRCODES_COUNT) {
      const qrCodesLimit = limits?.qrcodes_count;
      if (qrCodesLimit === "Unlimited" || qrCodesLimit === -1) return true;
      return (qrCodes || []).length < (qrCodesLimit as number || 0);
    }

    return !!limits[limitType];
  };

  const isFeatureDisabled = (value: Limits) => !isFeatureEnabled(value);

  const isFeaturePaused = () => !!account?.active_subscription?.paused_at;

  return { isFeatureEnabled, isFeatureDisabled, isFeaturePaused };
};
