import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";

import { RouterProvider } from "react-router-dom";
import { IntercomProvider } from "react-use-intercom";

import { ThemeProvider } from "styled-components";
import theme from "~/constants/theme";

import GlobalStyle from "~/globalStyle";

import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/700.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import { routes } from "~/router/routes";

import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import "~/translations/i18n";

import { analyticsSetup, getCreateRouterWithSentry } from "./helpers/analytics";
import { intercomKey } from "./helpers/environment";
import { ContextWrapper } from "./contexts";
import tagManagerSetup from "./helpers/tagManagerSetup";
import SuspenseFallback from "~/components/layout/SuspenseFallback";

analyticsSetup();
tagManagerSetup();

const createRouter = getCreateRouterWithSentry();
const router = createRouter(routes);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <IntercomProvider appId={intercomKey} autoBoot={false}>
      <ContextWrapper>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <Suspense fallback={<SuspenseFallback />}>
            <RouterProvider router={router} />
          </Suspense>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
          <ReactQueryDevtools initialIsOpen={false} />
        </ThemeProvider>
      </ContextWrapper>
    </IntercomProvider>
  </React.StrictMode>
);
