import { Icon } from "@fortawesome/fontawesome-svg-core";
import { faCog, faLifeRing } from "@fortawesome/pro-light-svg-icons";
import { faQrcode, faChartMixed } from "@fortawesome/pro-solid-svg-icons";
import { faQrCodeSquare } from "~/constants/customIcons";
import { paths } from "~/router/paths";
import { INavItem } from "~/types/routing";
import { useGetSidebarCountQrCodes } from "~/api/sidebarCounts/queries";

export const navItems: Array<INavItem> = [
  {
    namePath: "nav.generate",
    path: paths.app.generate,
    icon: faQrcode as Icon,
  },
  {
    namePath: "nav.qrcodes",
    path: paths.app.qrcodes,
    icon: faQrCodeSquare as Icon,
    getCountQuery: useGetSidebarCountQrCodes,
  },
  {
    namePath: "nav.analytics",
    path: paths.app.analytics,
    icon: faChartMixed as Icon,
  },
  {
    namePath: "settings.title",
    path: paths.settings.plans,
    icon: faCog as Icon,
  },
  {
    namePath: "nav.help_center",
    path: "https://aquatic-knife-cdd.notion.site/QRPro-c1349400088f4a6aa82e01bdd2062d90?pvs=25",
    icon: faLifeRing as Icon,
    showHelpCenterModal: true,
  },
];
