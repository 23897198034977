import { createQrCode, updateQrCode, getQrcode, searchProducts, getQrCodes } from "./requests";
import { useQuery, useMutation } from "@tanstack/react-query";
import { QRCODE, PRODUCTS, QR_CODE_LIST } from "./types";
import { IQrCode } from "~/types/qrcode";

export const useGetQrcode = (id?: string, isDisabled?: boolean) =>
  useQuery(QRCODE, () => getQrcode(id as string), { enabled: !!id && !isDisabled});

export const useSearchProducts = (keyword: string) =>
  useQuery([PRODUCTS, keyword], () => searchProducts(keyword));

export const useCreateQRCode = () => useMutation(createQrCode, { retry: false });

export const useUpdateQRCode = () => useMutation(updateQrCode, { retry: false });

export const useQrCodes = () =>
  useQuery<Array<IQrCode>>(QR_CODE_LIST, () => getQrCodes(), { cacheTime: 0 });
