import FlexContainer from "~/spocketUI/components/FlexContainer";
import * as S from "./styles";
import { useTranslation } from "react-i18next";
import ProgressBar from "~/spocketUI/components/ProgressBar";
import { useEffect, useState } from "react";
import { useAccount } from "~/hooks/useAccount";
import { CUSTOMIZATION, GENERATION_TYPE } from "~/constants/onboarding";
import { updateUserPreferences } from "~/api/account/requests";
import Button from "~/spocketUI/components/Button";
import { triggerShowPlansModal } from "~/spocketUI/helpers/customEvents";
import { SVG } from "~/spocketUI/components/SVG";
import { faChevronLeft } from "@fortawesome/pro-light-svg-icons";

export const Generate = () => {
  const { t } = useTranslation();
  const [generationType, setGenerationType] = useState<string[]>([]);
  const [showCustomization, setShowCustomization] = useState(false);
  const { account, getAccount } = useAccount();

  useEffect(() => {
    if (account?.qr_code_generation_type?.length && !generationType.length) {
      setGenerationType(account.qr_code_generation_type);
    }
  }, [account?.qr_code_generation_type]);

  const newGenerationTypes = GENERATION_TYPE
    ? [...GENERATION_TYPE, { name: t("onboarding.all"), value: "all" }]
    : [];

  const handleSelection = (value: string) => {
    setGenerationType((prev) => {
      if (prev.includes(value)) {
        return prev.filter((catValue) => catValue !== value);
      } else {
        return [...prev, value];
      }
    });
  };

  const areAllSelected = () => {
    return (
      generationType.length === GENERATION_TYPE.length &&
      generationType.every((catValue: string) =>
        GENERATION_TYPE.some((category) => category.value === catValue)
      )
    );
  };

  const handleAllSelection = () => {
    if (areAllSelected()) {
      setGenerationType([]);
    } else {
      setGenerationType(GENERATION_TYPE.map((category) => category.value));
    }
  };

  const handleBothSelections = (catValue: string) => {
    if (catValue === "all") {
      handleAllSelection();
    } else {
      handleSelection(catValue);
    }
  };

  const handleContinue = async () => {
    try {
      await updateUserPreferences({
        qr_code_generation_type: generationType,
      });
      getAccount();
      setShowCustomization(true);
    } catch (error) {
      console.error(error);
    }
  };

  if (showCustomization)
    return <Customization hide={() => setShowCustomization(false)} />;

  return (
    <FlexContainer flexDirection="column" gap="20px">
      <FlexContainer
        flexDirection="column"
        width="100%"
        gap="0px"
        alignItems="flex-start">
        <S.SelectTitle>{t("onboarding.category_title")}</S.SelectTitle>
        <ProgressBar progress={50} color="primary" />
      </FlexContainer>
      <FlexContainer gap="16px" flexWrap="wrap" width="100%">
        {newGenerationTypes.map((generation) => (
          <S.Card
            key={generation.value}
            className={
              generationType?.includes(generation.value) ? "active" : ""
            }
            onClick={() => handleBothSelections(generation.value)}>
            {t(generation.name)}
          </S.Card>
        ))}
      </FlexContainer>
      <FlexContainer justifyContent="space-between" width="100%">
        <Button
          bgColor="transparent"
          color="textSecondary"
          padding="16px 24px"
          onClick={() => setShowCustomization(true)}>
          {t("onboarding.skip")}
        </Button>
        <Button
          bgColor="primary"
          color="white"
          padding="16px 36px"
          radius="25px"
          onClick={handleContinue}>
          {t("onboarding.continue")}
        </Button>
      </FlexContainer>
    </FlexContainer>
  );
};

type Props = {
  hide: () => void;
};

export const Customization = ({ hide }: Props) => {
  const { t } = useTranslation();
  const [customizationOptions, setCustomizationOptions] = useState<string[]>(
    []
  );
  const { account, getAccount } = useAccount();

  useEffect(() => {
    if (
      account?.customization_options?.length &&
      !customizationOptions.length
    ) {
      setCustomizationOptions(account.customization_options);
    }
  }, [account?.customization_options]);

  const newCustomizationList = CUSTOMIZATION
    ? [...CUSTOMIZATION, { name: t("onboarding.all"), value: "all" }]
    : [];

  const handleSelection = (value: string) => {
    setCustomizationOptions((prev) => {
      if (prev.includes(value)) {
        return prev.filter((catValue) => catValue !== value);
      } else {
        return [...prev, value];
      }
    });
  };

  const areAllSelected = () => {
    return (
      customizationOptions.length === CUSTOMIZATION.length &&
      customizationOptions.every((catValue: string) =>
        GENERATION_TYPE.some((category) => category.value === catValue)
      )
    );
  };

  const handleAllSelection = () => {
    if (areAllSelected()) {
      setCustomizationOptions([]);
    } else {
      setCustomizationOptions(CUSTOMIZATION.map((category) => category.value));
    }
  };

  const handleBothSelections = (catValue: string) => {
    if (catValue === "all") {
      handleAllSelection();
    } else {
      handleSelection(catValue);
    }
  };

  const handleContinue = async () => {
    try {
      await updateUserPreferences({
        customization_options: customizationOptions,
      });
      getAccount();
      triggerShowPlansModal(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FlexContainer flexDirection="column" gap="20px">
      <FlexContainer
        flexDirection="column"
        width="100%"
        gap="0px"
        alignItems="flex-start">
        <FlexContainer width="100%" justifyContent="flex-start">  
          <Button padding="0" alignSelf="center" bgColor="transparent" onClick={hide}>
            <SVG icon={faChevronLeft} size="sm" color="textSecondary" />
          </Button>
          <S.SelectTitle>{t("onboarding.customize_title")}</S.SelectTitle>
        </FlexContainer>
        <ProgressBar progress={100} color="primary" />
      </FlexContainer>
      <FlexContainer gap="16px" flexWrap="wrap" width="100%">
        {newCustomizationList.map((option) => (
          <S.Card
            key={option.value}
            className={
              customizationOptions?.includes(option.value) ? "active" : ""
            }
            onClick={() => handleBothSelections(option.value)}>
            {t(option.name)}
          </S.Card>
        ))}
      </FlexContainer>
      <FlexContainer justifyContent="space-between" width="100%">
        <Button
          bgColor="transparent"
          color="textSecondary"
          padding="16px 24px"
          onClick={() => triggerShowPlansModal(true)}>
          {t("onboarding.skip")}
        </Button>
        <Button
          bgColor="primary"
          color="white"
          padding="16px 36px"
          radius="25px"
          onClick={handleContinue}>
          {t("onboarding.continue")}
        </Button>
      </FlexContainer>
    </FlexContainer>
  );
};
