export interface ICreateQrCodeRequest {
  title: string;
  destination_url: string;
  target_url?: string | boolean;
  color_background: string;
  color_foreground: string;
  frame?: number;
  frame_color?: string;
  logo_value?: string;
  header_text?: string | null;
  header_text_size?: string | number;
  header_text_color?: string;
  header_text_bold?: boolean;
  footer_text?: string | null;
  footer_text_size?: string | number;
  footer_text_color?: string;
  footer_text_bold?: boolean;
  left_text?: string | null;
  left_text_size?: string | number;
  left_text_color?: string;
  left_text_bold?: boolean;
  right_text?: string | null;
  right_text_size?: string | number;
  right_text_color?: string;
  right_text_bold?: boolean;
  shopify_product_id?: string | number;
}

export interface IUpdateQrCodeRequest {
  id: string;
  body: ICreateQrCodeRequest;
}

export interface ICreateQrCodeResponse {
  qr_code_data: {
    logo_url?: string;
    id: number;
    updated_at: string;
    created_at: string;
    title: string;
    destination_url: string;
    color_background: string;
    color_foreground: string;
    frame: number;
    frame_color: string;
    header_text: string;
    footer_text: string;
    right_text: string;
    left_text: string;
    header_text_size: string;
    footer_text_size: string;
    right_text_size: string;
    left_text_size: string;
    header_text_color: string;
    footer_text_color: string;
    right_text_color: string;
    left_text_color: string;
    token: string;
    target_url: string;
    logo_value?: string;
    shopify_product_id: string;
    user: number;
  };
  qr_image_data: string;
  shopify_product_info?: {
    id: number;
    name: string;
    image: string;
    url: string;
  };
}

export const QRCODE = ["QRCODE"];
export const PRODUCTS = "PRODUCTS";
export const QR_CODE_LIST = ["QR_CODE_LIST"];
