export const GENERATION_TYPE = [
  { name: "onboarding.product", value: "product" },
  { name: "onboarding.website", value: "website" },
  { name: "onboarding.email_address", value: "email_address" }
];

export const CUSTOMIZATION = [
  { name: "onboarding.colors", value: "colors" },
  { name: "onboarding.frames", value: "frames" },
  { name: "onboarding.shapes", value: "shapes" },
  { name: "onboarding.add_logo", value: "add_logo" },
  { name: "onboarding.add_text", value: "add_text" },
]
