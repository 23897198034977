import React, { useEffect, useState } from "react";
import * as S from "./PageWrapper.style";
import { UpgradeButton } from "./UpgradeButton";
import { TryForFreeBanner } from "./TryForFreeBanner";
import { excludePaths } from "~/constants/paths";
import { Notifications } from "~/components/notifications";
import { useMediaQuery } from "~/spocketUI/hooks/useMediaQuery";
import { PlansModal } from "~/components/plans/plansModal";
import { ResumeModal } from "~/components/resumeModal";
import { useAccount } from "~/hooks/useAccount";
import Modal from "~/spocketUI/components/Modal";
import { Generate } from "../onboarding";
import { useNavigate } from "react-router-dom";

interface IProps {
  children: React.ReactNode;
}

export const PageWrapper: React.FC<IProps> = ({ children = null }) => {
  const isSettingsPage = location.pathname.includes("settings");
  const showUpgrade = !excludePaths.find((path) => new RegExp(path).test(window.location.pathname));
  const isNotMobile = useMediaQuery("tablet");
  const isAboveDesktop = useMediaQuery("desktop");
  const queryParams = new URLSearchParams(location.search);
  const onboarding = queryParams.get("onboarding");
  const showRating = queryParams.get("show_rating");
  const isCheckout = location.pathname.includes("checkout");
  const planId = queryParams.get("plan_id");
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const { account } = useAccount();

  useEffect(() => {
    if (onboarding && !isCheckout) setShowModal(true);
    if (isCheckout) setShowModal(false);
    if (
      account &&
      !account?.active_subscription &&
      !account?.has_subscribed_before &&
      !planId &&
      !showRating && !isCheckout
    ) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set("onboarding", "true");
      navigate(`?${searchParams.toString()}`);
    } else {
      setShowModal(false);
    }
  }, [account, account?.active_subscription, planId, onboarding, isCheckout]);

  return (
    <S.PageWrapper
      padding={isSettingsPage ? "24px 25px 58px" : "24px 92px 58px"}>
      <S.PageHeader>
        <UpgradeButton />
        {isNotMobile ? <Notifications /> : null}
      </S.PageHeader>
      <S.PageMaxWidth>
        {showUpgrade ? <TryForFreeBanner /> : null}
        <>{children}</>
      </S.PageMaxWidth>
      <PlansModal />
      <ResumeModal />
      <Modal
        isShowing={showModal}
        minWidth={isAboveDesktop ? "66%" : "80%"}
        padding="40px">
        <Generate />
      </Modal>
    </S.PageWrapper>
  );
};

export const PageWrapperFullScreen: React.FC<IProps> = ({
  children = null,
}) => {
  return (
    <S.PageWrapperFullscreen>
      <PlansModal />
      <ResumeModal />
      <>{children}</>
    </S.PageWrapperFullscreen>
  );
};
