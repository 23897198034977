import { sendGet, sendPost, sendPut, sendDelete } from "~/api/base";
import { getAPIData } from "../helpers";
import { ICreateQrCodeRequest, IUpdateQrCodeRequest, ICreateQrCodeResponse } from "./types";

const apiURL: string = import.meta.env.VITE_API_URL;

export const createQrCode = (params: ICreateQrCodeRequest) =>
  getAPIData(sendPost("qr/create_qr_code_and_file/", params));

export const updateQrCode = (params: IUpdateQrCodeRequest) =>
  getAPIData(sendPut(`qr/qr-codes/${params.id}/update/`, params.body));

export const getQrcode = (id: string): Promise<ICreateQrCodeResponse> =>
  getAPIData(sendGet(`qr/qr-codes/${id}/show/`));

export const searchProducts = (keyword: string) =>
  getAPIData(sendGet("qr/search-products/", { keyword }));

export const getDownloadURL = (id: string, type: string) =>
  `${apiURL}/qr/download_qrcode_file/?qr_code_id=${id}&file_type=${ type }`;

export const getQrCodes = () => getAPIData(sendGet("qr/qr-code-list/"));

export const deleteQrCode = (id: string) => getAPIData(sendDelete(`qr/qr-codes/${id}`, {}));

