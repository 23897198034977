import { lazy } from "react";
import { app } from "~/helpers/routes";
import { paths } from "~/router/paths";

const GenerateQrCodePage = lazy(() => import("~/pages/generate-qrcode"));
const QrCodesPage = lazy(() => import("~/pages/qrcodes"));
const AnalyticsPage = lazy(() => import("~/pages/analytics"));

export const routes: Array<any> = [
  {
    path: paths.app.home,
    element: app(GenerateQrCodePage),
  },
  {
    path: paths.app.generate,
    element: app(GenerateQrCodePage),
  },
  {
    path: paths.app.editQrCode,
    element: app(GenerateQrCodePage),
  },
  {
    path: paths.app.qrcodes,
    element: app(QrCodesPage),
  },
  {
    path: paths.app.analytics,
    element: app(AnalyticsPage),
  },
];
