import { IVideo } from "~/types/video";

export const videos: IVideo[] = [
  {
    url: "//fast.wistia.net/embed/iframe/ga6kvgmdgl",
    titleKey: "video.overview",
    time: "0:47",
  },
  {
    url: "//fast.wistia.net/embed/iframe/lzpuphjp1u",
    titleKey: "video.install_signup",
    time: "0:56",
  },
  {
    url: "//fast.wistia.net/embed/iframe/ed1uczbllb",
    titleKey: "video.generate_qr",
    time: "0:49",
  },
  {
    url: "//fast.wistia.net/embed/iframe/6gue0mh4n7",
    titleKey: "video.manage_qr",
    time: "0:41",
  },
  {
    url: "//fast.wistia.net/embed/iframe/ef4cwoho4k",
    titleKey: "video.whats_analytics",
    time: "0:49",
  },
];
