export enum SubscriptionType {
  ANNUAL = "yearly",
  MONTHLY = "monthly"
}

export interface ILimit {
  qrcodes_count?: number | string;
  popular?: boolean;
  logo?: boolean;
  colors?: boolean;
  frame?: boolean;
  shape?: boolean;
  texts?: boolean;
  analytics?: boolean;
};

export enum Limits {
  QRCODES_COUNT = "qrcodes_count",
  POPULAR = "popular",
  LOGO = "logo",
  COLORS = "colors",
  FRAME = "frame",
  SHAPE = "shape",
  TEXTS = "texts"
}

export enum InvoiceStatus {
  PAID = "paid",
  UNPAID = "unpaid",
  DRAFT = "draft",
  PENDING = "pending",
  SUCCESS = "succeeded",
  FAILED = "failed",
}

export interface SubscriptionHistory {
  period_start: string;
  period_end: string;
}

export interface Invoice {
  id: number;
  invoice_pdf: string;
  amount: number;
  status: string;
  subscription_history: SubscriptionHistory;
  created_at: string;
}

export interface IPlan {
  id?: string,
  interval?: string,
  status?: string,
  cost_per_month: number,
  old_cost_per_month?: number,
  created_at?: string,
  updated_at?: string,
  features: string[]
  limits?: ILimit,
  trial_days?: number,
  name: string,
  is_highlighted: boolean,
  months_off: number
}

export interface IPaymentValues {
  name?: string;
  payment_method_id?: string;
  setup_intent?: string;
}

export interface IProration {
  old_plan_proration: number;
  new_plan_proration: number;
  total: number;
}
